@font-face {
	font-family: Gilroy-Medium;
	src: url(../../assets/fonts/Gilroy-Medium.ttf);
}


.wrapper {
	background-image: url("../../assets/images/bg-cream.png");
	margin-top: 50px;
	display: flex;
	align-items: center;
	padding: 100px;
	background-repeat: no-repeat;
	background-size: cover;
	gap: 40px;

	.videoWrapper {
		border-radius: 30px;
		height: 400px;
		width: 100%;
	}

	.sliderWrapper {
		width: 100%;


		.avatar {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 15px;



			p {
				font-family: Gilroy-Medium;
				font-size: 24px;
			}


			img {
				height: 50px;
				width: 50px;
			}
		}



		.itemText {
			width: 100%;
			margin-top: 15px;

			span {
				font-size: 15px;
				color: #5382A1;
				margin-top: 15px;
				
			}
			p {
				font-size: 24px;
				padding: 25px 0;
				font-family: Gilroy-Medium;
				color: #525252;
				width: 100%;
				line-height: 36px;
			}
		}


		.carousel .slide {
			height: 300px;
			display: flex;
		}
		.avatar {
			border-bottom: 1px solid #FF7A03;
			width: 95%;
		}
	}

	@media (max-width: 1100px) {
		flex-direction: column;
		padding: 30px;

		.videoWrapper {
			width: 100%;
			height: 400px;
			padding: 30px;
		}

		.sliderWrapper {
			width: 100%;
			padding: 30px;
			text-align: center;

			.avatar {
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 15px;
	
	
				img {
					height: 50px;
					width: 50px;
					margin: 0 auto;
				}
			}

			.itemText {
				width: 100%;
				margin-top: 15px;
	
				span {
					font-size: 15px;
					color: #5382A1;
					margin-top: 15px;
					
				}
				p {
					font-size: 16px;
					padding: 25px 0;
					color: #525252;
					width: 100%;
					line-height: 22px;
				}
			}
		}
	}
}


:global {
	.carousel-indicators {
		display: none  !important;
	}


.carousel-control-next, .carousel-control-prev {
	position: initial !important;
	display: initial !important;
}


.carousel-control-next-icon  {
	background-repeat: no-repeat;
    background-position: 50%;
     background-size: 15px !important;
	background-image: url("../../assets/images/arrow-right.png") !important;
}
.carousel-control-prev-icon  {
	background-repeat: no-repeat;
    background-position: 50%;
     background-size: 15px !important;
	background-image: url("../../assets/images/arrow-left.png") !important;

}
}
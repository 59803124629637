


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	
}

body {
	background-image: url("./assets/images/left.png");
	background-repeat: no-repeat;
	background-position-y: 30px;
	overflow-x: hidden;
	scroll-behavior: smooth;
}


a {
	color: #000;
}

#wrapper {
	width: 100%;
	background-image: url("./assets/images/right.png");
	background-repeat: no-repeat;
	overflow: hidden;
	background-position: right top;
	background-position-y: 20px;


	@media (max-width: 991px) {
		background: transparent !important;
	}
}
@font-face {
  font-family: Gilroy-Medium;
  src: url(../../assets/fonts/Gilroy-Medium.ttf);
}

.wrap {
  * {
    font-family: Gilroy-Medium;
  }
  .mediaNavbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    z-index: 10000;
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);

    .navbarTop {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .logo {
        width: 50px;
        height: 50px;
        margin-top: 100px;
        margin-bottom: 30px;
      }

      .exitBtn {
        right: 40px;
        top: 30px;
        position: absolute;
        cursor: pointer;

        i {
          font-size: 24px;
        }
      }
    }

    ul {
      text-align: center;
      padding: 0;
      li {
        font-size: 18px;
        cursor: pointer;
        line-height: 60px;
        list-style: none;
      }
    }

    .mediaPhoneNumber {
      text-align: center;
      border-top: 1px solid #ffb400;
      width: 70%;
      padding-top: 30px;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        li {
          a {
            transition: all 0.2s linear;

            &:hover {
              color: #ffb400;
            }
            i {
              font-size: 24px;
            }
          }
        }
      }

      .link {
        transition: all 0.2s linear;

        &:hover {
          color: #ffb400;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 60px 0 60px;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);
    width: 100%;
    max-height: 99px;

    .logo {
      transform: translate(20px, 26px);
    }

    .left {
      button {
      }
    }

    .hamburger,
    .mediaBtn {
      display: none;
    }

    .left,
    .right {
      display: flex;
      align-items: center;
      gap: 90px;
      padding: 43px;

      li {
        list-style: none;
        font-size: 18px;
        cursor: pointer;
        letter-spacing: 0.5px;
      }
    }

    @media (max-width: 991px) {
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .hamburger {
        position: absolute;
        cursor: pointer;
        display: block;

        i {
          font-size: 24px;
        }
      }

      .logo {
        margin-left: 38%;
        transform: translate(0, 40px);
      }

      .mediaBtn {
        display: block;

        a {
          padding: 8px;
          cursor: pointer;
          border-radius: 10px;
          background: linear-gradient(180deg, #ffb400 0%, #ffd65a 100%);
        }
      }

      .left,
      .right {
        display: none;
      }

      .logo {
        width: 80px;
        height: 80px;
      }
    }
  }
}

:global {
  .ant-modal-footer {
    display: none !important;
  }
}

.contactAbout {
  margin-top: 40px;
  text-align: center;
  h3 {
    padding: 15px 0;
    font-size: 36px;
  }
  p {
    margin: 0 auto;
    font-size: 20.4392px;
    line-height: 24px;
  }

  @media (max-width: 991px) {
    h3 {
      font-size: 22px;
    }

    p {
      font-size: 12px;
      width: 75%;
      line-height: 16px;
    }
  }
}

.inputs {
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .phoneInput {
    margin-top: 20px;

    width: 100%;
  }

  input {
    border: 0.3px solid #e4e4e4;
    width: 350px;
    padding-left: 45px;
    padding: 12px 50px;

    @media (max-width: 991px) {
      width: 300px;
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem #ffb400 !important;
    }
  }

  button {
    cursor: pointer;
  }

  .nameInput {
    padding: 12px 15px;
    outline: #ffb400;
  }

  @media (max-width: 991px) {
    button {
      font-size: 14px;
    }
  }
}

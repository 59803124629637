@font-face {
	font-family: Gilroy-SemiBold;
	src: url(../../../assets/fonts/Gilroy-SemiBold.ttf);
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	width: 90%;
	padding: 30px 0;


	.img {
		img {
			width: 80px;
		}
	}

	.text {
		text-align: center;
		p {
			color: #FFB400;
			font-size: 20px;
			font-family: Gilroy-SemiBold;
			line-height: 32px;
		}
	}


	@media (max-width: 991px) {
		
		.img {
			img {
				width: 70px;
			}
		}
		.text {
			
			p {
				line-height: 25px;

			}
		}
	}
}

.line {
	border-bottom: 1px solid #000;
}

.nothing {

	@media (max-width: 991px) {
		border-bottom: 1px solid #000;
	}
}

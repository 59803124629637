@font-face {
	font-family: Gilroy-Bold;
	src: url(../../assets/fonts/Gilroy-Bold.ttf);
}

.wrapper {
	margin-top: 50px;
	background-image: url("../../assets/images/blur.png");
	background-position: left center;
	background-position-x: -250px;
	background-position-y: 150px;
	background-repeat: no-repeat;

	.insideWrapper {
	background-image: url("../../assets/images/blur.png");
	background-position: right bottom;
	background-position-x: 1100px;
	background-position-y: 700px;
	background-repeat: no-repeat;
	}

	.myProducts {
		text-align: center;
		padding:  0 60px 0 60px;
	
		h1 {
			border-bottom: 1px solid #FFB400;
			width: 45%;
			font-size: 50px;
			padding: 10px 0;
			margin: 150px auto 0 auto;
			font-family: Gilroy-Bold;
		}
	}


	.text {
		 color: #000;
		 font-size: 22px;
		 font-weight: bold;
		 padding-bottom: 10px;
	}


.listOrder {
	width: 80%;

}


	.products {
		position: relative;
		padding: 0 60px 0 60px;

		ul {
			padding: 0;
		}


		li {
			list-style: none;
			font-size: 24px;
			line-height: 38px;
		}

	.line {
		position: absolute;
		height: 100%;
		top: 0;
		width: 1px;
		left: 50%;
		transform: rotate(180deg);
		background-color: #E4E4E4;
	}


	@media (max-width: 991px) {

		ul {
			padding: 0;
		}

		li {
			font-size: 16px;
			line-height: 22px;
		}
	}
	}

	@media (max-width: 991px) {
		.myProducts {
			padding: 0;
			text-align: center;
			h1 {
				width: max-content;
				border-bottom: 50%;
				font-size: 32px;
				margin-top: 50px;
			}
		}

		.line {
			display: none;
		}

		.text {
			color: #000;
			 font-size: 18px;
			 padding-bottom: 10px;
		}

	}
}



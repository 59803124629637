@font-face {
	font-family: Gilroy-Bold;
	src: url(../../assets/fonts/Gilroy-Bold.ttf);
}
@font-face {
	font-family: Gilroy-Medium;
	src: url(../../assets/fonts/Gilroy-Medium.ttf);
}
	.wrapper {
		background-color: #000;
		width: 100%;
		height: 100%;
		padding-top: 50px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		p {
			margin: 0;
		}
	

		.wrap {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding: 0 60px 0 60px;
		}


		.left {
			display: flex;
			align-items: center;
			gap: 20px;
			padding: 40px;

			h4 {
				color: #fff;
				font-size: 40px;
				font-family: 'Gilroy-Bold';
				line-height: 41px;
			}

			p {
				margin-top: -8px;
				color: #FFB400;
				font-size: 16px;
				font-family: Gilroy-Medium;
				line-height: 19px;
			}
		}

		.right {
			padding: 40px;
			display: flex;
			flex-direction: column;
			text-align: end;
			justify-content: end;


			a,p {
				font-family: Gilroy-Medium;

			}

			a {
				color: #fff;
				line-height: 21px;
				font-size:  18px;
				text-decoration: none;
			}

			p {
				color: #FFB400;
				font-size: 16px;
			}

			ul {
				display: flex;
				align-items: center;
				justify-content: end;
				gap: 30px;
				padding: 0;
				margin: 10px 0;

				li {
					a {
						transition: all .2s linear;

						&:hover {
							color: #FFB400;
						}
					}
				}
			}



			li {
				list-style: none;
			}
		}

		.poweredBy {
			padding: 15px 0 15px 100px;
			border-top: 1px solid #fff;
			width: 100%;
			font-family: Gilroy-Medium;
			line-height: 16px;
			color: #AAAAAA;
		}


		@media (max-width: 991px) {
		margin-top: 100px;
			.wrap {
				flex-direction: column;
				padding: 10px 0;
				justify-content: center;
				align-items: center;

				.left {
					flex-direction: column;
					justify-content: center;
					text-align: center;
					padding: 15px;

					.footerLeftText {
						display: none;
					}

					img {
						width: 60px;
						height: 60px;
					}
				}

				.right {
					padding: 0;
					text-align: center;
					margin: 0;

					ul {
						justify-content: center;
						margin-top: 20px;
						li {
							a {
								i {
									font-size: 20px;
								}
							}
						}
					}
				}
			}
		}

		@media (max-width: 991px) {
			text-align: center;
			.poweredBy {
				color: #AAAAAA;
				padding: 15px 0;
				border-top: 1px solid #fff;
				width: 100%;
				margin-top: 30px;
			}
	
		}
	}

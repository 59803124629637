@font-face {
	font-family: Gilroy-Medium;
	src: url(../../../assets/fonts/Gilroy-Medium.ttf);
}

.wrapper {
	display: flex;
	margin-top: 50px;
	gap: 150px;
	place-items: center;
	width: 100%;



	.image {
		width: 50%;
	img {
		width: 100%;
	}
	}




	.text {
		width: 50%;

	p {
		font-size: 24px;
		line-height: 36px;
		color: #525252;
		font-family: Gilroy-Medium;
		padding-right: 30px;
	}
	}


	@media (max-width: 991px) {

		display: grid;
		grid-template-columns: 1fr;
		gap: 30px;

		.image {
			width: 100%;

			img {
				width: 100%;
			}
		}
		.text {
			width: 100%;

			p {
				font-size: 16px;
				line-height: 22px;

			}
		}
	}
}


.reversing {
	flex-direction: row-reverse;


	@media (max-width: 991px) {
		display: flex;
		flex-direction: column-reverse;
	}

}

.row {
	flex-direction: row;

	@media (max-width: 991px) {
		display: flex;
		flex-direction: column-reverse;
	}
}
@font-face {
	font-family: Gilroy-Bold;
	src: url(../../assets/fonts/Gilroy-Bold.ttf);
}

.wrapper {
	margin-top: 150px;
	padding: 30px;




	img {
		width: 80%;
		height: 320px;
	}

	.myProducts {
		text-align: center;
		padding:  50px 60px 0 60px;
	
		h1 {
			border-bottom: 1px solid #FFB400;
			width: 45%;
			font-family: Gilroy-Bold;
			padding: 10px 0;
			margin: 0 auto;
			font-size: 50px;
			font-weight: bold;
		}
	}

	.mediaSwiper {
		display: none;
	}

	.swiperWrap {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
	}


	@media (max-width: 991px) {
		margin-top: 50px;

		z-index: 1000;
		img {
			height: 550px;
			width: 100%;
		}


	.myProducts {
		text-align: center;
		padding:  50px 70px 0 60px;
	
		h1 {
			width: 100%;
			font-size: 32px;
		}
	}

		.swiperWrap {
			display: none;

		}

		.mediaSwiper {
			display: block;
			margin: 0 auto;
			margin-top: 30px;


			img {
				width: 85%;
				margin-left: 25px;
			}

			:global {

				.swiper-pagination-horizontal {
					position: relative;
					margin-top: 20px;
					display: flex;
					justify-content: center;
				}
	
				.swiper-pagination-bullet-active {
					background: #FFB400;
					height: 10px;
					width: 10px;
				}
			}
		}
	}
}
 


:modal {
	.ant-modal {
		padding: 0 !important;
	}
}
.wrapper {
	padding: 0 60px 0 60px;
	display: flex;
	gap: 15px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 70px;
	position: relative;


	.blur {
		position: absolute;
		top: 550px;
		right: -70px;
		height: 300px;
	}


	.swiper {
		display: none;
	}


	img {
		height: 300px;
		border-radius: 20px;
		transition: all .2s linear;
		z-index: 100;
	}

	.imagesCategoryTop {
		display: grid;
		grid-template-columns: 1fr 1.5fr 0.6fr;
		gap: 15px;

		img {
			width: 100%;


			&:hover {
				transform: scale(.98);
			}
		}
	}


	.imagesCategoryBottom {
		display: grid;
		grid-template-columns: 1.5fr 0.9fr 1fr;
		gap: 15px;

		img {
			width: 100%;


			&:hover {
				transform: scale(.98);
			}
		}
	}


	@media (max-width: 991px) {
		padding: 0 30px;
		display: block;


		:global {
			.swiper-pagination-horizontal {
				position: relative;
				margin-top: 20px;
			}

			.swiper-pagination-bullet {
				margin: 0 2px !important;
			}

			.swiper-pagination-bullet-active {
				background: #FFB400;
				height: 10px;
				width: 10px;
			}
		}


		.swiper {
			display: block;
		}

		img {
			height: 250px;
			width: 100%;
			border-radius: 20px;
		}

		.imagesCategoryBottom,.imagesCategoryTop {
			display: none;
		}
	}
}
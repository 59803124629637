.btn {
	background: linear-gradient(180deg, #FFB400 0%, #FFD65A 100%);
	padding: 9px 28px;
	text-align: center;
	transition: 0.3s;
	font-size: 18px;
	background-size: 200% auto;
	color: #000;            
	border: none;
	box-shadow: 0 0 20px #eee;
	cursor: pointer;
	border-radius: 59px;
	display: block;

	&:hover {
	  background-position: right center;
	  color: #fff;
	  text-decoration: none;
	}

	a {
		display: flex;
		align-items: center;
		gap: 10px;
		color: #000 !important;


		img {
			height: 12px;
		}
	}
  }

 
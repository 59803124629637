@font-face {
	font-family: Gilroy-Bold;
	src: url(../../assets/fonts/Gilroy-Bold.ttf);
}

@font-face {
	font-family: Gilroy-SemiBold;
	src: url(../../assets/fonts/Gilroy-SemiBold.ttf);
}


@font-face {
	font-family: Gilroy-Regular;
	src: url(../../assets/fonts/Gilroy-Regular.ttf);
}

.wrapper {
	background-image: url("../../assets/images/blur.png");
	background-position-x: -350px;
	background-position-y: -380px;
	background-repeat: no-repeat;
	position: relative;
	padding-top: 50px;


	.blur {
		position: absolute;
		right: -350px;
	}

	.myProducts {
		text-align: center;
		padding:  50px 60px 0 60px;
	
		h1 {
			border-bottom: 1px solid #FFB400;
			width: 45%;
			font-family: Gilroy-Bold;
			padding: 10px 0;
			margin: 0 auto;
			font-size: 50px;
			font-weight: bold;
		}
		p {
			padding: 10px 0;
			line-height: 38px;
			font-size: 24px;
			margin: 0 auto;
			color: #525252;
		}

		@media (max-width: 991px) {
			padding: 70px 30px;

			h1 {
				border-bottom: 1px solid #FFB400;
				width: 100%;
				font-size: 32px;
				padding: 10px 0;
				line-height: 38px;
				margin: 0 auto;
				font-weight: bold;
			}
		}
	}

	.collapseWrapper {
		margin-top: 40px;
		padding: 0 60px 0 60px;
		display: flex;
		justify-content: space-between;
		gap: 30px;

		.collapseRight,.collapseLeft {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		@media (max-width: 991px) {
			padding: 30px;
			margin-top: -45px;
			flex-direction: column;
		}

	}
}

:global {
	.ant-collapse {
		z-index: 100 !important;
		background-color: transparent !important;
		border: 0.5px solid #E4E4E4 !important;
		box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06) !important;


		 .ant-collapse-content {
			border-top: none !important;
		}

		.ant-collapse-item {
			padding: 9px;
		}

		.ant-collapse-header {
			font-family: Gilroy-SemiBold;
			font-size: 24px;
			flex-direction: row-reverse;

			@media (max-width: 991px) {
				font-size: 20px;
			}
		}


		.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
			color: #000;
		}

		.ant-collapse-content-box {
			p {
				font-family: Gilroy-Regular;
				font-size: 20px;
				line-height: 26px;

				@media (max-width:991px) {
					font-size: 18px;
				}
			}
		}

		.ant-collapse-item-active .ant-collapse-header {
			.ant-collapse-header-text {
			  color:#FFB400 !important;
			}
		}

		.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
			color: #000;
		}

		.ant-collapse-item-active .ant-collapse-header {
			.ant-collapse-expand-icon {
				color: #FFB400;
			}
		}
	}
}
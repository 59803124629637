@font-face {
  font-family: Gilroy-Black;
  src: url(../../assets/fonts/Gilroy-Black.ttf);
}
@font-face {
  font-family: Gilroy-Medium;
  src: url(../../assets/fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: Gilroy-Bold;
  src: url(../../assets/fonts/Gilroy-Bold.ttf);
}

.wrapper {
  background-image: url("../../assets/images/blur.png");
  background-position: right bottom;
  background-position-x: 1000px;
  background-position-y: 240px;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;

  button {
    cursor: pointer;
  }

  .bannerItemWrapper {
    padding: 0 60px 0 60px;
    display: flex;
    align-items: center;

    .left {
      width: 100%;
      margin-top: 190px;
      padding: 0 100px 0 100px;
      h1 {
        font-size: 60px;
        text-transform: uppercase;
        font-family: Gilroy-Black;
      }
      p {
        font-size: 45px;
        width: 100%;
        font-family: Gilroy-Medium;
        line-height: 52.73px;
        color: #525252;
      }
      button {
        margin-top: 20px;
        font-family: Gilroy-Medium;
      }
    }

    .right {
      width: 120%;
      position: relative;
      img {
        width: 100%;
        max-width: 1000px;
        transform: translate(0, 100px);
      }

      .pharmacyBottom {
        position: absolute;
        height: 140px;
        width: 140px;
        right: 110px;
        top: 320px;
        z-index: -1;
      }
    }

    .personLeft {
      width: 75%;
      margin-top: 140px;
      z-index: 10000;
      padding: 0 100px 0 100px;
      h1 {
        font-size: 68px;
        font-family: Gilroy-Black;
        text-transform: uppercase;
      }
      p {
        font-size: 45px;
        width: 90%;
        line-height: 52.73px;
        font-family: Gilroy-Medium;
        color: #525252;
      }
      button {
        margin-top: 20px;
        font-family: Gilroy-Medium;
      }

      a {
        text-decoration: none;
      }
    }

    .personRight {
      width: 75%;
      position: relative;
      img {
        width: 100%;
        max-width: 1000px;
        transform: translate(0, 100px);
      }

      .person {
        width: 70%;
        z-index: -2;
      }

      .pharmacyBottom {
        position: absolute;
        height: 140px;
        width: 140px;
        right: 160px;
        top: 400px;
        z-index: 10;
      }
    }

    @media (max-width: 1100px) {
      flex-direction: column-reverse;
      padding: 0;

      .left,
      .right {
        width: 100%;

        .pharmacyBottom {
          display: none;
        }
      }

      .right {
        img {
          margin-top: 150px;
          margin-left: 20px;
        }
      }

      .left {
        width: 100%;
        padding: 0 30px;
        h1 {
          font-size: 2.2rem;
          font-weight: bold;
          text-transform: uppercase;
        }
        p {
          font-size: 28px;
          line-height: 33px;
          width: 90%;
          color: #525252;
        }
      }

      .personLeft {
        width: 100%;
        padding: 0 30px;
        h1 {
          text-transform: uppercase;
          font-family: Gilroy-Black;
          font-size: 2.2rem;
        }
        p {
          width: 100%;
          line-height: 33px;
          font-size: 28px;
          color: #525252;
        }

        a {
          text-decoration: none;
        }
      }

      .personRight {
        width: 100%;

        .person {
          height: 400px;
          width: 80%;
          margin-left: 30px;
          margin-top: 30px;
        }

        .pharmacyBottom {
          display: none;
        }
      }
    }
  }

  .blur {
    position: absolute;
    left: -250px;
    top: 500px;
    z-index: -1;
    height: max-content;
  }

  .aboutCompany {
    text-align: center;
    margin-top: -160px;
    // padding:  0 60px 0 60px;

    h1 {
      border-bottom: 1px solid #ffb400;
      width: 45%;
      font-size: 50px;
      padding: 10px 0;
      margin: 0 auto;
      font-weight: bold;
    }
    p {
      padding: 10px 0;
      width: 65%;
      line-height: 28px;
      font-size: 24px;
      margin: 0 auto;
      font-family: Gilroy-Medium;
      color: #525252;
    }
  }

  @media (max-width: 991px) {
    .aboutCompany {
      width: 100%;
      margin-top: -60px;

      h1 {
        border: 50%;
        width: max-content;
        font-size: 32px;
      }

      p {
        width: 80%;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.inputs {
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .phoneInput {
    margin-top: 20px;

    width: 100%;
  }

  input {
    border: 0.3px solid #e4e4e4;
    width: 350px;
    padding-left: 45px;
    padding: 12px 50px;

    @media (max-width: 991px) {
      width: 300px;
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem #ffb400 !important;
    }
  }

  button {
    cursor: pointer;
  }

  .nameInput {
    padding: 12px 15px;
    outline: #ffb400;
  }

  @media (max-width: 991px) {
    button {
      font-size: 14px;
    }
  }
}

.contactAbout {
  margin-top: 40px;
  text-align: center;
  h3 {
    padding: 15px 0;
    font-size: 36px;
  }
  p {
    margin: 0 auto;
    font-size: 20.4392px;
    line-height: 24px;
  }

  @media (max-width: 991px) {
    h3 {
      font-size: 22px;
    }

    p {
      font-size: 12px;
      width: 75%;
      line-height: 16px;
    }
  }
}

@font-face {
	font-family: Gilroy-Bold;
	src: url(../../assets/fonts/Gilroy-Bold.ttf);
}

@font-face {
	font-family: Gilroy-Medium;
	src: url(../../assets/fonts/Gilroy-Medium.ttf);
}

.wrapper {
	margin-top: 150px;
	height: 500px;
	display: flex;
	gap: 40px;
	padding: 0 200px 0 200px;
	justify-content: center;
	align-items: center;
	background-image: url("../../assets/images/restangle-bg.png");


	.image {
		width: 50%;
	}


	.text {
		width: 50%;

		p {
			width: 90%;
			font-size: 24px;
			line-height: 36px;
			font-family: Gilroy-Bold;
		}


		button {
			margin-top: 20px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 12px 28px;
			gap: 10px;
			border: none;
			font-family: Gilroy-Medium;
			cursor: pointer;
			font-size: 16px;
			line-height: 23px;
			color: #fff;
			background: #000000;
			border-radius: 59px;
			transition: all .2s linear;


			a {
				color: #fff;
				text-decoration: none;
			}



			&:active {
				transform: scale(.98);
			}
		}
	}

	@media (max-width: 991px) {
		flex-direction: column;
		padding: 0 30px;

		.image {
			width: 100%;
			margin: 0 auto;

			img {
				width: 70%;
				margin-left: 60px;
			}
		}

		.text {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			p {
				font-size: 16px;
				line-height: 22px;
			}

			button {
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
}
@font-face {
  font-family: Gilroy-Bold;
  src: url(../../assets/fonts/Gilroy-Bold.ttf);
}
@font-face {
  font-family: Gilroy-Medium;
  src: url(../../assets/fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: Gilroy-Regular;
  src: url(../../assets/fonts/Gilroy-Regular.ttf);
}

.wrapper {
  margin-top: 150px;
  background-image: url("../../assets/images/contact-bg-left.png");
  background-repeat: no-repeat;
  background-position-x: -50px;
  height: 90vh;
  .myProducts {
    text-align: center;
    padding: 50px 60px 0 60px;

    h1 {
      border-bottom: 1px solid #ffb400;
      width: 45%;
      padding: 10px 0;
      font-size: 50px;
      font-family: Gilroy-Bold;
      margin: 0 auto;
      font-weight: bold;
    }
  }

  .contactAbout {
    margin-top: 40px;
    text-align: center;

    h3 {
      padding: 15px 0;
      font-size: 36px;
      line-height: 42px;
      font-family: Gilroy-Medium;
    }
    p {
      width: 30%;
      margin: 0 auto;
      font-size: 20px;
      line-height: 24px;
      font-family: Gilroy-Regular;
    }
  }

  .inputs {
    width: 20%;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .wrapPhoneInput {
      width: 100%;
      margin: 0 auto;

      @media (max-width: 991px) {
        width: 80%;
      }
    }

    .phoneInput {
      margin-top: 20px;
      width: 100%;
    }

    input {
      border: 0.3px solid #e4e4e4;
      padding-left: 45px;
      padding: 12px 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:focus {
        box-shadow: 0 0 0 0.1rem #ffb400 !important;
      }
    }

    button {
      font-family: Gilroy-Medium;
      font-size: 20px;
      margin-top: 36px;
    }

    .nameInput {
      padding: 12px 15px;
      outline: #ffb400;
    }
  }

  @media (max-width: 991px) {
    background-image: url("../../assets/images/blur.png");
    background-position-x: -350px;
    background-position-y: -120px;
    height: 500px;
    margin-top: 50px;

    .myProducts {
      text-align: center;
      padding: 50px 60px 0 60px;

      h1 {
        width: 30%;
        font-size: 32px;
      }
    }

    .contactAbout {
      margin-top: 20px;
      text-align: center;

      h3 {
        padding: 15px 0;
        font-size: 24px;
        font-weight: 400;
      }
      p {
        width: 65%;
        margin: 0 auto;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .inputs {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;
      gap: 10px;
      align-items: center;

      .phoneInput {
      }

      .nameInput {
        padding: 12px 15px;
        margin: 0 auto;
        outline: #ffb400;
      }

      input {
        border: 0.3px solid #e4e4e4;
        // width: 300px !important;
      }

      @media (max-width: 460px) {
        padding: 0;
      }

      input {
        border: 0.3px solid #e4e4e4;

        &:focus {
          box-shadow: 0 0 0 0.1rem #ffb400 !important;
        }
      }

      .nameInput {
        padding: 12px 15px;
        width: 80%;
        margin: 0 auto;
        outline: #ffb400;
      }

      .phoneInput {
        width: 100%;
        margin: 0 auto;
        outline: #ffb400;
      }

      button {
        font-size: 14px;
        padding: 14px 24px;
      }
    }
  }

  .shine {
    display: flex;
    position: relative;
    color: #000;

    &::before {
      content: "Konsultatsiya olish";
      outline: none;
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffb400;
      background-image: linear-gradient(
        to right,
        #ffb400 0%,
        #edde5d 51%,
        #ffb400 100%
      );
      border-radius: 30px;
      animation-name: shimmer;
      animation-duration: 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
  }

  //   .btn-grad {
  // 	background-image: linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%);
  // 	margin: 10px;
  // 	padding: 15px 45px;
  // 	text-align: center;
  // 	text-transform: uppercase;
  // 	transition: 0.5s;
  // 	background-size: 200% auto;
  // 	color: white;
  // 	box-shadow: 0 0 20px #eee;
  // 	border-radius: 10px;
  // 	display: block;
  //   }

  //   .btn-grad:hover {
  // 	background-position: right center; /* change the direction of the change here */
  // 	color: #fff;
  // 	text-decoration: none;
  //   }

  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }

  @media (max-width: 420px) {
    .inputs {
      .phoneInput {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}

.wrap {
  background-image: url("../../assets/images/contact-bg-right.png");
  background-repeat: no-repeat;
  background-position-x: 1300px;
}

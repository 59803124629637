

.wrapper {
	background-image: url("../../assets/images/blur.png");
	background-position-x: -250px;
	background-position-y: -250px;
	background-repeat: no-repeat;
	padding: 40px;
	position: relative;

	.items {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		width: 100%;
		position: relative;



		.arrowLeft {
			position: absolute;
			left: 31.5%;
			width: 1px;
			background-color: #000;
			height: 100%;
		}
		.arrowRight {
			position: absolute;
			right: 35%;
			width: 1px;
			background-color: #000;
			height: 100%;
		}
	}

	.blur {
		position: absolute;
		bottom: 0;
		right: -350px;
		top: 5px;

	}



	@media (max-width: 991px) {
		
		padding: 30px;

		.items {
			grid-template-columns: 1fr 1fr;


			.arrowLeft {
				display: none;
			}


			.arrowRight {
				right: 52%;
			}
		}
	}
}
